import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { ButtonLink } from "../components/button";
import SearchPosts from "../components/searchPosts";
import Layout from "../components/layout";
import { Feature } from "../features/features";
import { Text } from "../components/typography";

function Blog(props) {
  const { data, navigate, location } = props;
  let flagString = "";
  let flags: Feature[] | undefined = undefined;
  let isDraftMode = false;
  if (typeof window !== "undefined") {
    flagString = localStorage.getItem("flags") || "{}";
    flags = JSON.parse(flagString) as Feature[];
    if (flags.length) {
      isDraftMode =
        flags.filter((feature: Feature) => feature.name === "draftMode")[0]
          .enabled || false;
    }
  }
  const posts = data.allMdx.edges.filter(
    (post) => post.node.frontmatter.mode === "published" || isDraftMode
  );
  const localSearchBlog = data.localSearchBlog;

  return (
    <Layout {...props}>
      <SEO title="All posts" />
      <Text variant="large_C">
        A blog. Take a minute to read something if you like.
      </Text>
      <SearchPosts
        posts={posts}
        localSearchBlog={localSearchBlog}
        navigate={navigate}
        location={location}
      />
      <ButtonLink to="/">Go Home</ButtonLink>
    </Layout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            mode
          }
        }
      }
    }
  }
`;
