import React from "react";
import { useTheme } from "./ThemeProvider";
import { Link } from "gatsby";
import styled from "styled-components";
import { Theme } from "../theme/theme";
import { Text } from "./typography";

const PostInfoContainer = styled.div((props: { theme: Theme }) => ({
  marginTop: 30,
  paddingTop: 5,
  paddingLeft: 10,
  paddingBottom: 10,
  paddingRight: 10,
  ":hover": {
    boxShadow: `-5px 5px 0px 5px ${props.theme.semanticColors.cardHover}`,
  },
}));

const BoxLink = styled(Link)`
  display: block;
  outline-color: ${(props) => props.theme.semanticColors.bodyText};
  :hover {
    box-shadow: -5px 5px 0px 5px
      ${(props) => props.theme.semanticColors.cardHover};
  }
  :focus {
    box-shadow: -5px 5px 0px 5px
      ${(props) => props.theme.semanticColors.cardHover};
  }
`;

function PostInfo(props: {
  title: string;
  slug: string;
  date: string;
  description: string;
  excerpt: string;
}) {
  const { title, slug, date, description, excerpt } = props;
  const theme = useTheme();
  return (
    <BoxLink to={`/blog${slug}`} theme={theme}>
      <PostInfoContainer theme={theme}>
        <Text variant={"large_C"} style={{ marginTop: 0 }}>
          {title}
        </Text>
        <Text
          variant={"small_C"}
          style={{ marginBottom: 10, display: "block" }}
        >
          {description || excerpt}
        </Text>
        <Text variant="small_B">{date}</Text>
      </PostInfoContainer>
    </BoxLink>
  );
}

export { PostInfo };
