import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import * as queryString from "query-string";
import { isEnterKey } from "../util/dom";
import { PostInfo } from "./postInfo";
import { TextField } from "@fluentui/react";
import { Text } from "./typography";
import { useTheme } from "./ThemeProvider";

const SearchBar = styled.div`
  display: flex;
  border: 1px solid #dfe1e5;
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  background: #fdfdfd;

  svg {
    margin: auto 1rem;
    height: 20px;
    width: 20px;
    color: #9aa0a6;
    fill: #9aa0a6;
  }

  input {
    display: flex;
    flex: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    padding-right: 0.5rem;
    color: rgb(55, 53, 47);
    word-wrap: break-word;
    outline: none;
  }
`;

const SearchedPosts = ({ results }) =>
  results.length > 0 ? (
    results.map((node) => {
      const date = node.date;
      const title = node.title || node.slug;
      const description = node.description;
      const excerpt = node.excerpt;
      const slug = node.slug;

      return (
        <div key={slug} style={{ margin: "20px 0 40px" }}>
          <PostInfo
            date={date}
            title={title}
            description={description}
            excerpt={excerpt}
            slug={slug}
          />
        </div>
      );
    })
  ) : (
    <Text
      variant="medium_D"
      style={{ textAlign: "center", display: "block", margin: "14px 0px" }}
    >
      Sorry, couldn't find any posts matching this search.
    </Text>
  );

const AllPosts = ({ posts }) => (
  <div style={{ margin: "20px 0 40px" }}>
    {posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug;
      return (
        <div key={node.fields.slug}>
          <PostInfo
            date={node.frontmatter.date}
            title={title}
            description={node.frontmatter.description}
            excerpt={node.excerpt}
            slug={node.fields.slug}
          />
        </div>
      );
    })}
  </div>
);

const SearchPosts = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { posts, localSearchBlog, location } = props;
  const { search } = queryString.parse(location.search);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState(
    location.state?.queryParam || search || ""
  );
  const [searchBoxValue, setSearchBoxValue] = useState<string>("");

  let results;
  if (query) {
    results = useFlexSearch(
      query,
      localSearchBlog.index,
      localSearchBlog.store
    );
  }

  useEffect(() => {
    // adding this isLoaded because fluent component does not load correctly on SSR
    // this fixes the issue
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded && (
        <TextField
          iconProps={{ iconName: "search" }}
          placeholder="Search posts"
          id="search"
          type="search"
          value={searchBoxValue}
          onChange={(e) => setSearchBoxValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (isEnterKey(e) && e.currentTarget.value) {
              window.location.href = `/blog/?search=${e.currentTarget.value}`;
            }
          }}
          styles={{ root: { margin: "10px 0px" } }}
        />
      )}
      {query ? <SearchedPosts results={results} /> : <AllPosts posts={posts} />}
    </>
  );
};

export default SearchPosts;
